import React from "react";
import Link from "next/link";

function Home() {
  return (
    <div className="container cards">
      <div className="row">
        <div className="column">
          <div className="card">
            <h3>Currency Rates</h3>
            <img width="100" src="/cn.svg"></img>
            <p>
              If you're looking to exchange <b>local to foreign</b> or{" "}
              <b>foreign to local</b> currency, these are the rates to look for.
            </p>
            <Link href="/lkr-currency-exchange-rates">
              <a className="button button-outline">View all rates</a>
            </Link>
          </div>
        </div>
        <div className="column">
          <div className="card">
            <h3>Demand Draft Rates</h3>
            <img width="100" src="/dd.svg"></img>
            <p>
              When changing or issuing foreign <b>bank drafts (checks)</b>,
              these are the rates to look for
            </p>
            <Link href="/lkr-demand-draft-exchange-rates">
              <a className="button button-outline">View all rates</a>
            </Link>
          </div>
        </div>
        <div className="column">
          <div className="card">
            <h3>TT (Wire) Rates</h3>
            <img width="100" src="/tt.svg"></img>
            <p>
              If you're doing <b>bank to bank transfers</b> either from local to
              foreign or foreign to local banks these are the rates to look for.
            </p>
            <Link href="/lkr-telegraphic-transfer-exchange-rates">
              <a className="button button-outline">View all rates</a>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
